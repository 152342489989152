<template>
  <div>
    <v-toolbar color="secondary" extended dark>
      <v-toolbar-title class="d-flex align-center">
        <v-icon left>
          mdi-account
        </v-icon>

        <span>
          My profile
        </span>
      </v-toolbar-title>
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="mt-n16">
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>Username</th>
                    <td>{{ user.username }}</td>
                  </tr>
                  <tr>
                    <th>Organization</th>
                    <td>{{ user.organizationName }}</td>
                  </tr>
                  <tr>
                    <th>Roles</th>
                    <td>{{ $store.getters.rolesFromToken }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-slide-y-transition>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                Change password
              </v-card-title>

              <v-card-subtitle>
                To change your password, start by providing your current password.
              </v-card-subtitle>

              <v-card-text>
                <v-form
                  ref="passwordForm"
                  v-model="passwordFormValid"
                  lazy-validation
                >
                  <v-text-field
                    v-model="currentPassword"
                    type="password"
                    label="Current password"
                    :rules="rules.currentPassword"
                    :error-messages="currentPasswordErrors"
                    outlined
                  ></v-text-field>

                  <div class="mb-1">
                    Your new password must meet the following criteria:
                  </div>

                  <div class="d-flex flex-column mb-4" style="gap: .25rem">
                    <div>
                      <v-fade-transition leave-absolute group>
                        <v-icon v-if="newPasswordHasCorrectLength" key="valid" left color="success"> mdi-check-circle-outline </v-icon>
                        <v-icon v-else key="invalid" left> mdi-circle-small </v-icon>
                      </v-fade-transition>

                      Minimum 8 characters
                    </div>
                    <div>
                      <v-fade-transition leave-absolute group>
                        <v-icon v-if="newPassword.match(/\d/)" key="valid" left color="success"> mdi-check-circle-outline </v-icon>
                        <v-icon v-else key="invalid" left> mdi-circle-small </v-icon>
                      </v-fade-transition>

                      Minimum 1 number
                    </div>
                    <div>
                      <v-fade-transition leave-absolute group>
                        <v-icon v-if="newPassword.match(/\D/)" key="valid" left color="success"> mdi-check-circle-outline </v-icon>
                        <v-icon v-else key="invalid" left> mdi-circle-small </v-icon>
                      </v-fade-transition>

                      Minimum 1 letter
                    </div>
                  </div>

                  <v-text-field
                    v-model="newPassword"
                    type="password"
                    label="New password"
                    :rules="rules.newPassword"
                    outlined
                  ></v-text-field>

                  <v-text-field
                    v-model="confirmPassword"
                    type="password"
                    label="Confirm password"
                    :rules="rules.confirmPassword"
                    autocomplete="new-password"
                    outlined
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="changing" @click="changePassword">
                  <v-icon left> mdi-lock-reset </v-icon>
                  Change password
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-slide-y-transition>

      <v-slide-y-transition>
        <v-row v-if="showPasswordChangeSuccess">
          <v-col>
            <v-alert type="success" elevation="2">
              <template #close>
                <v-btn icon @click="showPasswordChangeSuccess = false">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>

              <template #append>
                <v-btn class="mr-2" color="primary" @click="logout">
                  <v-icon left> mdi-logout </v-icon>
                  Log out now
                </v-btn>
              </template>

              <template #default>
                <div>Your password was successfully changed.</div>

                <div class="mt-2 font-weight-bold">If you are using the browser's built-in password manager, we recommend logging in again using your new password to update it.</div>
              </template>
            </v-alert>
          </v-col>
        </v-row>
      </v-slide-y-transition>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data() {
    return {
      changing: false,
      passwordFormValid: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      currentPasswordErrors: '',
      showChangePassword: false,
      showPasswordChangeSuccess: false,
      rules: {
        currentPassword: [
          v => !!v || 'Please provide your current password'
        ],
        newPassword: [
          v => !!v || 'A new password is required',
          v => v?.length >= 8 || 'Must be minimum 8 characters',
          v => !!v && !!String(v).match(/\d/) || 'Password must contain at least 1 number',
          v => !!v && !!String(v).match(/\D/) || 'Password must contain at least 1 letter'
        ],
        confirmPassword: [
          v => !!v || 'Please confirm by writing your new password again',
          v => !!v && !!this.newPassword && v === this.newPassword || 'Passwords must match'
        ]
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.currentUser?.profile;
    },
    newPasswordHasCorrectLength() {
      return this.newPassword?.length >= 8;
    }
  },
  methods: {
    async changePassword() {
      if (!this.$refs.passwordForm.validate())
        return;

      try {
        this.changing = true;
        this.currentPasswordErrors = '';

        const response = await this.$Services.users.changePassword(this.user.sub, this.currentPassword, this.newPassword);

        this.showPasswordChangeSuccess = true;
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.$refs.passwordForm.resetValidation();
      } catch (error) {
        if (error.response?.status === 400) {
          this.currentPasswordErrors = 'Current password is not correct. Please check for typos and try again.';
        } else {
          console.error('Error changing password', error);
          this.$store.dispatch('showDefaultError');
        }
      } finally {
        this.changing = false;
      }
    },
    logout() {
      this.$Services.auth.logout();
    }
  }
}
</script>